import React from 'react';

import SEO from "../../components/en/seo/Seo";
import Header from "../../components/en/headers/Header";
import Footer from "../../components/en/layouts/Footer";
import ExploreFirstHalf from '../../components/en/explore-mine/ExploreFirstHalf';
import ExploreSecondHalf from '../../components/en/explore-mine/ExploreSecondHalf';
import Dictionary from "../../components/en/explore-mine/Dictionary";
import ExploreHeader from "../../components/en/explore-mine/ExploreHeader";
import CallToAction from "../../components/en/layouts/CallToAction";
import ExploreDocuments from "../../components/en/explore-mine/ExploreDocuments";

const IndexPage = () => (
    <>
        <SEO
            title="Experience the richness of minerals and the stories of the miners"
            description="The Sitarjevec Litija mine is a modern underground museum, that offers its visitors an insight into the diversity of the geological natural heritage, the world of bats, spiders and fungi, into the richness of the mining tradition and the interpretation of its richness through the eyes of artists."
        />
        <Header />
        <ExploreHeader/>
        <ExploreFirstHalf/>
        <ExploreSecondHalf/>
        <Dictionary/>
        <ExploreDocuments/>
        <CallToAction/>
        <Footer/>
    </>
);


export default IndexPage