import React from 'react';
import DocumentItem from "./DocumentItem";
import MiningHeritagePdf from '../../../assets/downloadables/mining-heritage-in-slovenia.pdf';
import MineralFest2018Pdf from '../../../assets/downloadables/mineral-fest-2018.pdf';
import SimpozijRudnikSitarjevecPdf from '../../../assets/downloadables/simpozij-rudnik-sitarjevec.pdf';

const documents = [
    {
        title: "Mining heritage as Slovenian tourist attraction",
        description: "The Sitarjevec Litija Mine is one of the stirrups of the Mining Heritage in Slovenia: the Geological Institute of Slovenia presented the Heritage of Mining in Slovenia at an exhibition in Canada. The most prominent experts in the field of knowledge of the geological structure of the territory of Slovenia also selected the Sitarjevec Litija Mine for presentation.",
        downloadFile: MiningHeritagePdf
    },
    {
        title: "MineralFest Ljubljana 2018",
        description: "Prominent Slovenian experts who have been researching the mysteries of the Sitarjevec Mine for decades met at a professional symposium in Litija. The anthology gives an insight into the content of scientific research and the latest findings from researchers.",
        downloadFile: MineralFest2018Pdf
    },
    {
        title: "1. Expert symposium on the Sitarjevec Mine",
        description: "The Sitarjevec Litija mine is regularly represented at specialized fairs. In 2018 we were special guests of the Mineral, Fossil and Jewellery Mineralfest fair in Ljubljana.",
        downloadFile: SimpozijRudnikSitarjevecPdf
    }
];

function ExploreDocuments(props) {
    return (
        <div>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12 col-md-12 col-xl-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Follow up materials</h2>
                                </div>
                            </div>
                        </div>

                        { documents.map(documentItem => (
                            <DocumentItem key={documentItem.title} documentItem={documentItem} />
                        )) }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExploreDocuments;