import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function ExploreFirstHalf(props) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">

                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-12 col-md-12 col-xl-5 order-1 mt-4 order-xl-0">
                            <div className="thumbnail">
                                <StaticImage
                                  src="../../../assets/images/rudnik/sestdeset-60-razlicnih-vrst-mineralov.jpg"
                                  alt="The Sitarjevec mine is one of the polymer mining ores with exceptional mineral diversity."
                                  placeholder="blurred"
                                  imgClassName="img-fluid w-100"
                                  loading="lazy"
                                />

                                <div style={{"fontSize": "13px", "color": "#757575", "fontWeight": "300"}}>
                                    The author of the mineral collection is Blaž Zarnik.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xl-7 order-0 order-xl-1">
                            <div className="p-2 p-lg-4">
                            <div className="section-title">
                                    <h2 className="title">Sixty different types of minerals</h2>

                                    <p className="description">
                                        The Sitarjevec mine is one of the poly mineral ore grounds with an exceptional mineral diversity. It is one of the richest sites of various minerals on European and global scale. It contains more than sixty different minerals, the most important minerals are galenite, sphalerite, cinnabar, chalcopyrite and barit.
                                    </p>

                                    <p className="description">
                                        he world mineral heritage includes cinnabar crystals that are up to 6 cm high and sets of cerussite crystals that are up to 10 cm high as the largest crystals of the time. The mineral rarity on a global scale is presented in a form of worms of self-native lead. For grinding jewellery is a great hematite-vulture ore, a semi-precious stone that impresses with its red and gray  color.
                                    </p>

                                    <p className="description">
                                        The mine contained rich deposits of lead and mercury ore, as well as ores containing zinc, copper, iron and barit. The Sitarjevec mine was one of the largest deposits of lead ore on a European scale. One of the most attractive properties of the minerals in the Sitarjevec mine is their glow in the whole range of colors, which is caused by the breaking of light on their surface. You can see the glow of some of them in the Export Pit of the Mine.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*second*/}
            <div className="about-wrapper ptb--120 bg_color--3">
                <div className="container">

                    {/*First */}
                    <div className="row align-items-center text-center text-lg-left">

                        <div className="col-lg-7 col-md-12 col-xl-7">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Limonite dripstones</h2>

                                    <p className="description">
                                        The visibility of the Sitarjevec mine in the wider area is distinguished by exceptional limonite dripstone structures such as stalagmites, stalactites, dripstone curtains, dripstone spaghetti, which are unique in the European space. They grow at high speed in an abandoned mine tunnels. Under favorable conditions such as a constant inflow of mineral-enriched cave waters, temperature, humidity and balanced microflora, the caps grow up to 5 cm per year.
                                    </p>

                                    <p className="description">
                                        The colorfulness and picturesqueness of the mine pits is complemented by yellow limonite mud, which is an excellent natural pigment. The Sitarjevec Litija Mine is the only known place of growing limonite dripstones in Central Europe. Limonite dripstone structures can be admired in the Main Pit.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-xl-5">
                            <div className="thumbnail mt-4">
                                <StaticImage
                                    src="../../../assets/images/rudnik/limonitni-kapniki.jpg"
                                    alt="Limonite stalactites in the Sitarjevec Mine are now available for viewing."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*third*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">

                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 col-xl-5 order-1 order-lg-0">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../../assets/images/rudnik/izjemna-barvitost-rudnika-v-litiji-2021.jpg"
                                    alt="The exceptional color in the interior of the mine is one of the special features of the Mine in Litija."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-xl-7 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Exceptional coloring of the mine</h2>
                                    <p className="description">The Sitarjevec mine is distinctly colorful. The warm yellow colors of the iron pigment are visible throughout the mine, in the Export pit at the sides and on the ceiling, and in the Main pit also on the bottom in the form of mining mud. The excellent pigment is suitable for painting and dyeing textiles, which the visitor can see in the exhibition in the Export pit.
                                    </p>

                                    <p className="description">
                                        In addition to the okra, the red hematite and white barite are also useful in Sitarjevec for painting purposes. The Sitarjevec colors create a special atmosphere, as the artists expressed with a light performance of unusual lamps and fireflies, which were hung under the ceiling of the mine shaft in the Export pit.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*forth*/}
            <div className="about-wrapper ptb--120 bg_color--3">
                <div className="container">

                    <div className="row row--35 align-items-center text-center text-lg-left">

                        <div className="col-lg-7 col-md-12 col-xl-7">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Mountain formation and tectonic slides</h2>
                                    <p className="description">
                                        The Litija’s ore field with Sitarjevec is stretched in a series of wrinkled rocks, which were formed by the high pressures of the mountainous processes. Tectonic events caused individual blocks to rise or descend, stack rocks and elsewhere to burst, or cause numerous tectonic fault lines. This created tectonic slides which like a mirror, smoothed the surface of the groundbreaking surface. The rocks of today's Posavic hills folds formed along the equator, which is a testament to the journey of the continents and how long the rocks have traveled in 300 million years. After a long journey, they can be admired at the Sitarjevec Litija Mine.
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-xl-5">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../../assets/images/rudnik/gorotvorni-procesi.jpg"
                                    alt="Tectonic events in the vicinity of the Sitarjevec Litija mine caused individual blocks to rise or fall and caused numerous tectonic faults."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*fifth*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left ">
                        <div className="col-xl-5 col-12 order-1 order-xl-0">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                  src="../../../assets/images/rudnik/interpretacija-paleookolja.jpg"
                                  alt="Reconstruction and interpretation of the paleoenvironment in the lithium mine."
                                  placeholder="blurred"
                                  imgClassName="img-fluid"
                                  loading="lazy"
                                />

                                <div style={{"fontSize": "13px", "color": "#757575", "fontWeight": "300"}}>
                                    The author of the Diorama of the Carboniferous landscape is Anže Jurkovšek.
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-12 order-0 order-xl-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Reconstruction and interpretation of the paleoenvironment</h2>

                                    <p className="description">
                                        In the long 300 million years, due to the movement of lithosphere slabs of rock from the area of the equator together with plant fossils, they also traveled to the area of Sitarjevec. Based on fossils that were also found in the wider area around Litija, we know that the vegetation was significantly different from todays. Lush forests consisted of up to 40 m high tree ferns and first gymnosperms.
                                    </p>

                                    <p className="description">
                                        In the marsh forests, many large amphibians were managed, including the giant dragonfly, Meganeura, with a wingspan of up to 75 cm. Sculptures of plant-thick in a half-filled Export pit depict a mysterious seemingly fairytale world that, much like a pictorial reconstruction, is a careful snapshot of the scientifically researched living world at the end of the ancient Earth's ages.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ExploreFirstHalf;