import React from 'react';

const dictonary2 = [
    {word: "FOKSL", meaning: "Wood wedge"},
    {word: "FORT KAHEL", meaning: "Metal cable car"},
    {word: "MAŠINCA", meaning: "Shooting, blasting maschine"},
    {word: "LAMPA", meaning: "Personal mining lamp"},
    {word: "PELIGAN", meaning: "Reinforced wooden pillar"},
    {word: "ŠAJBA", meaning: "Personal running cave number"},
    {word: "ŠAHT", meaning: "Export air transition shaft"},
    {word: "ŠALA", meaning: "Cage in the shaft"},
    {word: "FIRENK", meaning: "Cage guide in the shaft"},
    {word: "ŠPRAHROR", meaning: "Pipeline communication"},
    {word: "ŠUFERCA", meaning: "Leg wrapping cloth"},
    {word: "ŠUSMOJSTER", meaning: "Shooter for blasting in the pit"},
    {word: "FALA", meaning: "The last part of the tree trunk, plank"},
    {word: "ŠIHT", meaning: "An eight-hour work day"},
    {word: "ŠTAJGER", meaning: "Supervisor, foreman"},
    {word: "HAJER", meaning: "First-time digger"},
    {word: "LEERHAJER", meaning: "Assistang of the digger"},
    {word: "LAUFAR", meaning: "Beginner or unqualified miner"},
    {word: "OBERŠTAJGER", meaning: "Manager"},
    {word: "CAJH", meaning: "Tool"},
    {word: "HERC", meaning: "Coal shovel"},
    {word: "KRAMP", meaning: "Digger"},
    {word: "HINKO", meaning: "Excavation hammer"},
    {word: "PORKHAMER", meaning: "Excavation digging maschine"},
    {word: "KROCA", meaning: "Large hoe"},
    {word: "ŠTEMPEL", meaning: "Support iron or wooden stand"},
    {word: "RIGEL", meaning: "Sub-ceiling"},
    {word: "UNTERCUH", meaning: "Cross-border sub-ceiling"},
    {word: "KVEL", meaning: "Wooden crack"},
    {word: "ŠPRUDEL", meaning: "Piece of plank"},
    {word: "FORTNA", meaning: "Cave ladder"},
    {word: "ŠTREKNA", meaning: "Pit excavation, tunnel"},
    {word: "GEZENK", meaning: "Angle oliquely pit"},
    {word: "AVPRZUH", meaning: "Demolition of the ceiling behind the excavation"},
    {word: "LEGNT", meaning: "Tallings"},
    {word: "MAŠINHAUS", meaning: "Export machine gun"},
    {word: "URLAUB", meaning: "Holiday"},
];

function Dictionary(props) {
    return (
        <div>
            <div className="about-wrapper pb--120 pt--120 bg_color--3 text-center">
                <div className="container">
                    <div className="row row--20 align-items-center">

                        <div className="col-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">Miner’s dictionary</h2>
                                    <p className="description">The language was also formed in the Sitarjevec mine caves.</p>
                                </div>

                                <div className="row">
                                    { dictonary2.map(item => (
                                        <div className="col-md-3 col-6" key={item.word}>
                                            <div className="about-us-list">
                                                <h5 className="title">{item.word}</h5>
                                                <p className="w-100 text-center pr-0">{item.meaning}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dictionary;