import React from 'react';
import { Link } from 'gatsby';

function CallToAction(props) {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper ptb--120">
            <div className="container pt-4 pt-md-0 pb-4 pb-md-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h2>Are you ready to experience the Litija’s unique underground?</h2>
                            <p className="cta-description mt--60 mb-0 font-weight-normal text-white">Tours are possible from Thursday to Sunday from 9:00 - 12:00 and 15:00 - 18:00</p>
                            <p className="font-weight-normal mt-1 text-white">Visits of the mine are only possible by prior arrangement. Group visits are possible even outside business hours</p>
                            <Link className="btn-default btn-border btn-large btn-white mt--40" to="/en/price-list">View the price list</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallToAction;