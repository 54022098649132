import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function ExploreSecondHalf(props) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--3">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12 col-md-12 col-xl-12 text-center">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">The oldest Slovenian mine?</h2>
                                    <p className="description">The exploitation of ore in the area of Sitarjevec is going back to the distant past. Archaeological sites in the vicinity indicate that they were mining in Litija even 4,000 years ago. From this period, the remains of dwellings and slag were found in the area of the present-day Litija Primary Court. On the Hill Sitarjevec (448 m) the remains of a settlement from the younger Iron Age were found. From the top there is a beautiful view of the Sava River, where there was once an important transport route, ideal for the development of mining in this area which the Romans took an advantage of and started digging underground.
                                    </p>

                                    <p className="description">The mine was already operational in the Middle Ages. From archival sources we know that in Šmartno pri Litiji, the mining judge for Dolenjsko region had a seat which speaks of the great importance of the mine because Šmartno was only a small village at the time. The preserved tombstone of Christof Bruckerschmied from 1537 which is now stored in church of St. Martin in Šmartno pri Litiji represents the most likely mining judge and not the mining master, as it was claimed in the past.
                                    </p>

                                    <p className="description">At the time polyhistor Janez Vajkard Valvasor lived at Bogenšperk Castle, where he also wrote The Glory of the Duchy of Carniola, the mine was closed. The cause of the closure is not yet known. Valvasor mentions old mining on the basis of large halds found (tailings embankment from the mine). Among other things he wrote that the mine was once a big one, but it was now abandoned. The mine only reopened in the last quarter of the 19th century. They took advantage of a different ore, especially lead and mercury. In addition to this, they also dug ore containing zinc, copper, iron and, in the last period of operation, mainly barit</p>

                                    <p className="description">Many owners were replaced throughout the history of the mine and mining has been repeatedly suspended. In February 1965, there was a commission meeting in Litija on the dissolution of the mine. They found that mining research did not deliver the expected results. The low content of the metal in the ore no longer allowed for viable excavation, the technical equipment of the cave was poor, the ore bodies were small and displaced in the room, making it impossible to update the way they worked. Problems with processing devices and lung diseases of miners (silicosis) also contributed to the deterioration of the situation. The Commission therefore proposed to stop the work in the cave. The production of lead and barit was still alive until the end of July 1965 before closing the plants.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb">
                                    <StaticImage
                                        src="../../../assets/images/rudnik/zgodovina-rudnika-sitarjevec-vhod-v-glavni-rov.jpg"
                                        alt="History of the Sitarjevec Mine entrance to the main tunnel"
                                        placeholder="blurred"
                                        imgClassName="img-fluid w-100 h-100"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb h-100">
                                    <StaticImage
                                        src="../../../assets/images/rudnik/zgodovina-rudnika-sitarjavca-2.jpg"
                                        alt="Group picture in front of the Sitarjevec mine - history of the mine"
                                        placeholder="blurred"
                                        imgClassName="img-fluid w-100 h-100"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*second*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-12 order-1 order-lg-0">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../../assets/images/rudnik/netopirji-mali-podkovnjak.jpg"
                                    alt="Bats (Mali Podkovanjak) in the Sitarjevec Litija Mine."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Lesser horseshoe bat (Rhinolophus Hipposideeros)</h2>
                                    <p className="description">
                                        Bats are the only mammals that can fly. There are more than 1,000 species in the world, 30 of them in Slovenia. The mine is home to a protected species called the Lesser horseshoe bat, named after the horseshoe shape of its mouth. They are very small, weighing up to 10 grams and measuring from 3-5 centimetres. They are special not only because of the flight, but also because of the way they are orientating in the room with the help of inaudible ultrasound, which also helps them hunt and with hibernation – just as bears they sleep through the winter.
                                    </p>

                                    <p className="description">
                                        The mine has always been their place of residence. They go through the hatch on the door at night to hunt for mosquitoes and other insects to ensure the balance in the wild. They return to the cave again in the morning and sleep through the day in the calmer and hidden corners of the mine.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*third*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--3">
                <div className="container">

                    <div className="row row--35 align-items-center text-center text-lg-left">

                        <div className="col-lg-7 col-md-12 col-xl-7">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Melting factory and beekeeping lawsuit</h2>
                                    <p className="description">
                                        In 1880, for the purpose of melting the excavated ore from the Sitarjevec Mine, they built the smeltery, on the left bank of the Sava River, in which a lead and mercury ore were melted. Ore was transported over the River Sava by a freight cable car that was 364 meters long, as well as wagons over a wooden bridge built in 1855. The melting factory caused a number of problems as it had a negative impact on the people, animal and plant health due to the releases of toxic sulphur gases. Despite the additional and higher chimneys built by management due to numerous complaints about pollution, in 1920 there was a mass death of bees.
                                    </p>

                                    <p className="description">
                                    The beekeeping lawsuit is the name of the struggles of Litija’s beekeepers against the Litija melting factory after the bees died. The final verdict was reached after several years at the High Court in Zagreb in favour of beekeepers. The smeltery in Litija was closed and the machines were moved to Topusko in Croatia. Beekeeping lawsuit (1921–1926) was the first successful ecological lawsuit in Slovenia.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-xl-5">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../../assets/images/rudnik/topilnica-in-cebelarska-pravda.jpeg"
                                    alt="Melting factory and beekeeping lawsuit"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*forth*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">

                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                            <div className="thumbnail mt-4">
                                <StaticImage
                                    src="../../../assets/images/rudnik/litijski-srebrnik.png"
                                    alt="The Sitarjevec mine is one of the polymer mining ores with exceptional mineral diversity."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Silver coin (Litija’s tolar)</h2>
                                    <p className="description">In 1886, silver was successfully obtained in the Litija’s melting factory. The silver occurs in a small amount in the lead ore. In the then main state mint in Vienna 3.6999 kg of silver was sent, from which commemorative silver coins – Litija’s tolars – were made. On the front is depicted the then melting factory, the train, the church of St. Nikolas, Sitarjevec hill and mining cable car to the solvent. On the other side is the name of the mine with the symbolically written "first silver view".
                                    </p>

                                    <p className="description">The silver coin can also be purchased from us in the form of a memory magnet.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ExploreSecondHalf;